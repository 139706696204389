import request from '@/utils/request'

export function searchCompany(param) {
    return request({
        url: '/foodshop/food/shop/findCompany',
        method: 'POST',
        data: param
    })
}

export function findCompanyAddress(companyId) {
    return request({
        url: '/foodshop/food/shop/findCompanyAddress/'+companyId,
        method: 'GET'
    })
}


/** 查询企业订单 */
export function findCompanyOrder(params) {
    return request({
        url: '/foodshop/food/shop/findCompanyOrder',
        method: 'POST',
        data: params
    })
}

/** 查询运费 */
export function findAddrFreight(params) {
    return request({
        url: `/foodshop/food/shop/findAddrFreight/${params.townId}/${params.addrId}`,
        method: 'GET'
    })
}

