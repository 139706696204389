<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warId" clearable>
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index"
                               :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryParams.companyId" clearable
                        filterable remote reserve-keyword
                        placeholder="企业名称"
                        :remote-method="remoteMethod"
                        :loading="searchCompanyLoading">
                    <el-option v-for="item in companyOption" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryParams.driverId" placeholder="请选择司机" clearable filterable>
                    <el-option v-for="(item, index) in drivers" :label="item.driverName" :value="item.guid" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                    :editable="false"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    v-model="queryParams.localDates"
                    placeholder="请选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="15" class="mb20">
            <el-col :span="24">
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    :size="$store.getters.size"
                    v-if="$app.haveRole('delivery:record:deletes')"
                    :disabled="multipleSelection.length <= 0"
                    @click="handleDeletes"
                >批量删除</el-button>
                <!--<el-button
                    type="primary"
                    icon="el-icon-plus"
                    :size="$store.getters.size"
                    v-if="$app.haveRole('delivery:supplement:add')"
                    @click="$refs['saveDelivery'].show()"
                >添加补单</el-button>-->
            </el-col>
        </el-row>

        <el-table :data="tableData" :size="$store.getters.size" v-loading="loading" ref="table"
                  @selection-change="handleSelectionChange" row-key="guid" :row-class-name="tableRowClassName">
            <el-table-column type="expand" width="1">
                <template slot-scope="scope">
                    <el-form label-width="100px" :size="$store.getters.size" class="record_form">
                        <el-form-item label="司机姓名">{{scope.row.driverName}}</el-form-item>
                        <el-form-item label="企业名称">{{scope.row.companyName}}</el-form-item>
                        <el-form-item label="送货地址">{{scope.row.address}}</el-form-item>
                        <el-form-item label="出框数量">{{scope.row.leaveBasketNum}}</el-form-item>
                        <el-form-item label="回筐数量">{{scope.row.returnBasketNum}}</el-form-item>
                        <el-form-item label="出豆腐板数量">{{scope.row.leavePlankNum}}</el-form-item>
                        <el-form-item label="回豆腐板数量">{{scope.row.returnPlankNum}}</el-form-item>
                        <el-form-item label="送货图"><el-image :src="item" v-for="(item, index) in imgUrlSplit(scope.row.deliverImgs)"
                                                            @click="imageView(scope.row, index, 1)" :key="index"/></el-form-item>
                        <el-form-item label="出框图"><el-image :src="item" v-for="(item, index) in imgUrlSplit(scope.row.leaveBasketImgs)"
                                                            @click="imageView(scope.row, index, 2)" :key="index"/></el-form-item>
                        <el-form-item label="回筐图"><el-image :src="item" v-for="(item, index) in imgUrlSplit(scope.row.returnBasketImgs)"
                                                            @click="imageView(scope.row, index, 3)" :key="index"/></el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="司机姓名" prop="driverName" width="120"></el-table-column>
            <el-table-column label="出框数量" prop="leaveBasketNum" width="88" align="center"></el-table-column>
            <el-table-column label="回筐数量" prop="returnBasketNum" width="88" align="center"></el-table-column>
            <el-table-column label="出豆腐板数量" prop="leavePlankNum" width="100" align="center"></el-table-column>
            <el-table-column label="回豆腐板数量" prop="returnPlankNum" width="100" align="center"></el-table-column>
            <el-table-column label="送达时间" prop="createTime" width="180"></el-table-column>
            <el-table-column label="企业名称" prop="companyName" width="260"></el-table-column>
            <el-table-column label="地址" prop="address" width="360"></el-table-column>
            <el-table-column label="操作" width="260">
                <template slot-scope="scope">
                    <el-button type="text" @click="handleStock(scope.row)"
                                v-if="scope.row.orderId && $app.haveRole('delivery:record:stock')">补货/缺货</el-button>
                    <el-button type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
                               v-if="$app.haveRole('delivery:record:delete')">删除</el-button>
                    <el-button type="text" @click="toogleExpand(scope.row)">{{scope.row.expansion ? '收起' : '查看详情'}}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <page-item :total="total" ref="pageItem"/>
        <image-view ref="imageView"/>
        <record-stock ref="recordStock"/>
    </div>
</template>

<script>
    import PageItem from '@/components/PageItem/'
    import ImageView from '@/components/ImageView/'
    import RecordStock from './stock'
    import {getAllDriver} from '@/api/system/driver'
    import { getList,deletes,remove } from '@/api/delivery/delivery'
    import {searchCompany} from '@/api/system/company'

    export default {
        name: "DeliveryRecord",
        components: {PageItem, ImageView, RecordStock},
        data(){
            return {
                queryParams: {
                    warId: undefined,
                    localDates: undefined,
                    driverId: undefined,
                    companyId: undefined
                },
                warehouseOption: this.$store.getters.warehouse,
                drivers: [],
                tableData: [],
                total: 0,
                loading: false,
                multipleSelection: [],
                companyOption: [],
                searchCompanyLoading: false
            }
        },
        methods: {
            /** 获取列表数据 */
            getTableData(){
                if(!this.queryParams.warId){
                    this.$message.error("请选择仓库");
                    return;
                }
                if(this.loading){return;}
                this.loading = true;
                this.queryParams.size = this.$refs['pageItem'].pageSize;
                this.queryParams.current = this.$refs['pageItem'].current;
                getList(this.queryParams).then(({code, data})=>{
                    this.loading = false
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                })
            },
            /** 查询 */
            querySearch(){
                this.$refs['pageItem'].current = 1;
                this.getTableData();
            },
            handleStock(row){
                this.$refs['recordStock'].handleOpen(row.orderId,row.driverType);
            },
            /** 删除 */
            handleDelete(row){
                this.$confirm("你确定要删除此数据?","提示",{
                    type: "warning"
                }).then(() => {
                    remove(row.guid).then(({code})=>{
                        if(code === 0){
                            this.$message.success("删除成功");
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})
            },
            /** 批量删除 */
            handleDeletes(){
                if(this.multipleSelection.length<=0){
                    this.$message.error("未选择需要删除的数据")
                    return false;
                }
                this.$confirm("你确定要删除选中的数据","提示",{
                    type: "warning"
                }).then(()=>{
                    const ids = this.multipleSelection.map(item => item.guid);
                    deletes(ids).then(({code})=>{
                        if(code === 0){
                            this.$message.success("批量删除成功");
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})
            },
            /** 多选 */
            handleSelectionChange(val){
                this.multipleSelection = val;
            },
            /** 图片地址分隔 */
            imgUrlSplit(urls){
                if(urls) {
                    return urls.split(",");
                }else{
                    return [];
                }
            },
            /** 高亮显示 */
            tableRowClassName({row}){
                if(row.whetherStock){
                    return 'error-row'
                }
            },
            imageView(row, index, type){
                let img_1 = this.imgUrlSplit(row.deliverImgs)
                let img_2 = this.imgUrlSplit(row.leaveBasketImgs)
                let img_3 = this.imgUrlSplit(row.returnBasketImgs)
                if(type === 2){
                    index = img_1.length + index
                }else if(type === 3){
                    index = img_1.length + img_2.length + index
                }
                let imgs = img_1.concat(img_2, img_3)
                this.$refs['imageView'].index = index;
                this.$refs['imageView'].urls = imgs
                this.$refs['imageView'].isShow = true;
            },
            toogleExpand(row){
                let $table = this.$refs.table;
                this.tableData.map(item => {
                    if (row.guid !== item.guid) {
                        $table.toggleRowExpansion(item, false)
                        item.expansion = false
                    } else {
                        item.expansion = !item.expansion
                    }
                })
                $table.toggleRowExpansion(row)
            },
            remoteMethod(query){
                if(!this.queryParams.warId){
                    this.$message.error("请选择仓库")
                    return false
                }
                if(!query){
                    this.companyOption = []
                    return
                }
                let param = {warId: this.queryParams.warId, companyName: query}
                searchCompany(param).then(({code, data}) => {
                    if(code === 0){
                        this.companyOption = data
                    }
                })
            }
        },
        watch: {
            'queryParams.warId': function (val, oldVal) {
                if(val) {
                    getAllDriver({warId: val}).then(({code, data}) => {
                        if (code === 0) {
                            this.drivers = data;
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .record_form {
        .el-image {
            margin: 10px;
            width: 110px;
            height: 110px;
        }
        .el-image:hover{
            border: 1px solid #EBEEF5;
        }
    }
</style>
