<template>
    <el-dialog :visible.sync="show" width="560px" :close-on-click-modal="false" title="补货/缺货">
        <el-table :data="tableData" :size="$store.getters.size" height="500px" border :default-sort="{prop:'type'}"
            v-loading="loading">
            <el-table-column label="产品名称" prop="productName"></el-table-column>
            <el-table-column label="规格" prop="specs"></el-table-column>
            <el-table-column label="数量" prop="num"></el-table-column>
            <el-table-column label="类型" align="center">
                <template slot-scope="scope">
                    <el-tag size="mini" type="warning" effect="plain" v-if="scope.row.type === 1">缺货</el-tag>
                    <el-tag size="mini" effect="plain" v-if="scope.row.type === 2">补货</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
            <el-button :size="$store.getters.size" @click="handleClose">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { stockList } from '@/api/delivery/delivery'

    export default {
        name: "RecordStock",
        data(){
            return {
                show: false,
                tableData: [],
                loading: false
            }
        },
        methods: {
            handleOpen(orderId,driverType){
                this.tableData = [];
                this.loading = true;
                console.log(orderId)
                stockList({orderId: orderId,driverType:driverType}).then(({code, data})=>{
                    this.loading = false;
                    if(code === 0){
                        this.tableData = data;
                    }
                })
                this.show = true;
            },
            handleClose(){
                this.tableData = [];
                this.show = false;
            }
        }
    }
</script>

<style scoped>

</style>
